import {AxiosError, AxiosResponse} from 'axios';

export type ApiResponseErrorOneItemPerDay = {
    header?: number;
    subHeader?: string;
};
export type ApiResponseErrorDetails = {
    message: string;
    code: number;
    hint?: string;
};

export interface ApiResponseMultipleErrorsDetails {
    messages: {
        error?: ApiResponseErrorDetails[];
    };
}

export type AxiosErrorWithApiResponseErrorDetails<
    T extends
        | ApiResponseErrorDetails
        | ApiResponseMultipleErrorsDetails
        | ApiResponseErrorOneItemPerDay =
        | ApiResponseErrorDetails
        | ApiResponseMultipleErrorsDetails
        | ApiResponseErrorOneItemPerDay
> = AxiosError & {
    response?: AxiosResponse<T>;
};

const DEFAULT_ERROR_MESSAGE = 'Request failed with unknown reason';

/**
 * Returns an error message by response
 *
 * @param {ApiResponseErrorDetails} errorDetails - details or the error
 * @returns {string} - error message
 */
function getErrorMessageByErrorDetails(errorDetails: ApiResponseErrorDetails): string {
    return errorDetails.message;
}

function getErrorMessageOneItemPerDay(errorDetails: ApiResponseErrorOneItemPerDay): string {
    return `${errorDetails.header}${errorDetails.subHeader ? ` ${errorDetails.subHeader}` : ''}`;
}

/**
 * Returns an error message from the backend response
 *
 * @param {{}} responseDetails - response detaile has been gotten from Backend API
 * @returns {string} - error message
 */
function getErrorMessageByAxiosErrorWithMultipleErrorsDetails(
    responseDetails: Required<
        AxiosErrorWithApiResponseErrorDetails<ApiResponseMultipleErrorsDetails>
    >['response']['data'],
): string {
    return responseDetails.messages.error
        ? responseDetails.messages.error.map(getErrorMessageByErrorDetails).join(`
    `)
        : DEFAULT_ERROR_MESSAGE;
}

/**
 * Returns an error message by redux action
 * payload from error response
 *
 * @export
 * @template T
 * @param {T} axiosError - response details in axios format
 * @returns {string} - error message
 */
export function getErrorMessageByAxiosErrorWithErrorDetails<
    T extends AxiosErrorWithApiResponseErrorDetails
>(axiosError: T): string {
    const errorDetails = axiosError.response?.data;
    if (!errorDetails) return DEFAULT_ERROR_MESSAGE;
    if ('messages' in errorDetails && errorDetails.messages)
        return getErrorMessageByAxiosErrorWithMultipleErrorsDetails(errorDetails);
    if ('message' in errorDetails && errorDetails.message)
        return getErrorMessageByErrorDetails(errorDetails);
    if ('header' in errorDetails && errorDetails.header)
        return getErrorMessageOneItemPerDay(errorDetails);
    return DEFAULT_ERROR_MESSAGE;
}
