import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import {Feature} from 'components/feature/feature';
import {DROP_DOWN_MENU_LINKS} from 'constants/menu';
import {Icon} from 'components/__helpers/icon/icon';
import flags from 'constants/flags/flags';

import s from './dropDownItems.scss';

type PropsType = {
    name: string;
};

export const DropDownItems: React.FC<PropsType> = ({name}) => {
    const dynamicClasses: {[key: string]: string} = {
        shopall: s.shopAll,
        coopervision: s.cooperVision,
        bauschlomb: s.bauschLomb,
        ultra: s.ultra,
        clariti: s.clarity,
        biotrue: s.biotrue,
        airoptimax: s.airoptimax,
        biomedics: s.biomedics,
        acuvue: s.acuvue,
        biofinity: s.biofinity,
        dailies: s.dailies,
        proclear: s.proclear,
    };
    const lowercaseName = name.toLowerCase();

    const links = Object.keys(DROP_DOWN_MENU_LINKS[lowercaseName]).map(
        key => DROP_DOWN_MENU_LINKS[lowercaseName]?.[key],
    );

    const contactLensesLinks = DROP_DOWN_MENU_LINKS.contacts.menuBrands.links;

    const contactLensesMenuLinks = DROP_DOWN_MENU_LINKS.contacts.menuLinks.links;

    return (
        <div className={s.container}>
            {lowercaseName === 'contacts' && flags.dUHCCL.isEnabled() ? (
                <div className={s.contactsContainer}>
                    <div className={s.menuItems}>
                        {contactLensesMenuLinks?.map(item => (
                            <Link key={item.title} className={s.menuLink} to={item.link}>
                                <span>{item.title}</span>
                            </Link>
                        ))}
                    </div>

                    <div className={s.contactLensesList}>
                        {contactLensesLinks?.map(item => (
                            <Link
                                key={item.name}
                                className={classNames(s.brandLink, s.smallButton)}
                                to={item.link}
                            >
                                <span
                                    className={classNames(
                                        dynamicClasses[`${item.name.toLowerCase()}`],
                                        s.icon,
                                    )}
                                >
                                    {item.name === 'ShopAll' ? (
                                        <span className={s.shopAll}>Shop All</span>
                                    ) : (
                                        <Icon size="responsive" name={`${item.name}Brand`} />
                                    )}
                                </span>
                            </Link>
                        ))}
                    </div>
                </div>
            ) : (
                <div className={classNames(s.list, s[`${lowercaseName}List`])}>
                    {links?.map(item =>
                        item.name === 'Safety' ? (
                            <Feature key={item.name} flag="categorySafetyUHCGD">
                                <Link
                                    key={item.name}
                                    className={classNames(
                                        s.item,
                                        s[`${lowercaseName}${item?.name?.replace(/\s/g, '')}`],
                                    )}
                                    to={item.link}
                                >
                                    {item.name}
                                </Link>
                            </Feature>
                        ) : (
                            <Link
                                key={item.name}
                                className={classNames(
                                    {[s.item]: lowercaseName !== 'brands'},
                                    {[s.brands]: lowercaseName === 'brands'},
                                    s[`${lowercaseName}${item?.name?.replace(/\s/g, '')}`],
                                )}
                                to={item.link}
                            >
                                {lowercaseName === 'brands' && item.name !== 'Shop All Brands' ? (
                                    // @ts-ignore
                                    <Icon name={`${item.name}Brand`} />
                                ) : (
                                    item.name
                                )}
                            </Link>
                        ),
                    )}
                </div>
            )}
        </div>
    );
};
