import {createReducer} from 'redux-act';
import {includes, filter, append, mergeRight} from 'ramda';

import {RMA_STATES} from '../../../constants/common';
import {
    changeRmaState,
    selectRmaOrderItem,
    toggleRmaOrderItems,
    clearSelectedRmaOrderItem,
} from '../../actions/actions';
import {RmaResponseType, RmaDataType} from '../../../types';
import {firstStepAdapter} from '../../../libs';

export const initialState: RmaDataType['firstStep'] = {
    selectedOrderItems: [],
    selectedOrderItemsType: null,
    contactLenses: [],
    qty: {},
    glassesAndAccessories: [],
    isRmaNotEligibility: false,
    isRmaRefund: false,
    token: '',
};

/**
 * Reducer rma improved first step.
 */
export const firstStep = createReducer({}, initialState)
    .on(`${changeRmaState}_FULFILLED`, (state, {data: response}: RmaResponseType) => {
        switch (response.state) {
            case RMA_STATES.ORDER_ITEMS:
                return mergeRight(state, firstStepAdapter(response.data));
            default:
                return state;
        }
    })
    .on(selectRmaOrderItem, (state, orderItem) => {
        const {selectedOrderItems} = state;
        return mergeRight(state, {
            selectedOrderItems: includes(orderItem, selectedOrderItems)
                ? filter(({id}) => id !== orderItem.id, selectedOrderItems)
                : append(orderItem, selectedOrderItems),
        });
    })
    .on(toggleRmaOrderItems, (state, orderItemType) =>
        mergeRight(state, {
            selectedOrderItems: [],
            selectedOrderItemsType: orderItemType,
        }),
    )
    .on(clearSelectedRmaOrderItem, state => ({
        ...state,
        selectedOrderItems: [],
        selectedOrderItemsType: null,
    }));
