/* eslint-disable max-lines */
import {contains, prop, propOr, range, mergeRight} from 'ramda';

import {Maybe} from 'constants/typescript/types';

import {
    RmaOptionsType,
    RmaResponseOrderItemsDataType,
    RmaQtyType,
    OrderItemsType,
} from '../types/firstStepType';
import {RETURN_OPTIONS, RMA_ORDER_ITEMS_TYPE, RETURN_TYPE} from '../constants/common';
import {
    RmaResponseDataOfferExchangeAdaptedType,
    RmaResponseDataOfferExchangeType,
} from '../types/thirdStepTypes';
import {
    RmaResponseDataConfirmationType,
    RmaAdaptedDataConfirmationType,
} from '../types/fourthStepType';
import {RmaAdaptedSummaryDataType, RmaResponseDataSummaryType} from '../types/summaryType';

export enum RMA_LISTS {
    MAIN_PAGE,
    REFUND,
    OUTSIDE,
    CANADA,
}

/**
 * Get RMA status that is not usa country
 *
 * @param {string} countryCode - rma status code.
 * @returns {boolean} - not usa country.
 */
export const isNonUsaCountryCode = (countryCode: string): boolean =>
    !contains(countryCode, ['US', 'CA']);

/**
 * Get Param from options in data RMA on first and third steps
 *
 * @param {string} key - optionType of item rma.
 * @param {RmaOptionsType[]} options - options of item rma.
 * @param {string | number} defaultValue - options of item rma.
 * @returns {any} - value of option.
 */
const getOptionsParamFromData = (
    key: string,
    options: RmaOptionsType[],
    defaultValue: string | number = '-',
): string => propOr(defaultValue, 'value')(options.find(option => option.type === key));

/**
 * Adapts the data order items structure.
 *
 * @param {RmaResponseOrderItemsType[]} data - raw data.
 * @returns {orderItems} - adapted structure,
 */
export const firstStepAdapter = ({
    items,
    rmaEligibility,
    isOutdated,
    token,
}: RmaResponseOrderItemsDataType) => {
    const result = items
        .sort(({type}) => (type === 'accessories' ? 1 : -1))
        .reduce<OrderItemsType>(
            (
                acc,
                {
                    options = [],
                    upgrades = [],
                    contactLens,
                    id,
                    sku,
                    status,
                    product,
                    type,
                    returned,
                    rmaType,
                    qty = {od: 0, os: 0},
                    color = {od: '', os: ''},
                },
            ) => {
                const commonField = {
                    id,
                    sku,
                    status,
                    product,
                    returned,
                    contactLens,
                    upgrade: upgrades.map(upgradeItem => upgradeItem.name).join(', ') || '-',
                    lensType: getOptionsParamFromData('lens_type', options),
                    lensTypeName: getOptionsParamFromData('lens_type', options)?.split(' ')[0],
                    lensesPerBox: getOptionsParamFromData('lenses_per_box', options, 0),
                    isRmaTypeReturns: rmaType === 'returns',
                    color: getOptionsParamFromData('color', options),
                };

                if (type === 'contact_lenses') {
                    acc[RMA_ORDER_ITEMS_TYPE.CONTACT_LENSES].push({
                        ...commonField,
                        qty,
                        packages: getOptionsParamFromData('package', options),
                        colorLenses: color,
                        type,
                    });
                } else {
                    acc[RMA_ORDER_ITEMS_TYPE.GLASSES_AND_ACCESSORIES].push({
                        ...commonField,
                        type,
                    });
                }

                return acc;
            },
            {
                [RMA_ORDER_ITEMS_TYPE.CONTACT_LENSES]: [],
                [RMA_ORDER_ITEMS_TYPE.GLASSES_AND_ACCESSORIES]: [],
            },
        );

    return mergeRight(result, {
        isRmaNotEligibility: items.every(item => !item.status.eligibleForRma) || isOutdated,
        isRmaRefund: rmaEligibility === 'rma_refund',
        token,
    });
};

/**
 * Adapts the data structure for third step.
 *
 * @param {RmaResponseDataOfferExchangeType} data - third step data.
 * @returns {RmaResponseDataOfferExchangeAdaptedType} - adapted structure,
 */
export const offerExchangeAdapter = (
    data: RmaResponseDataOfferExchangeType,
): RmaResponseDataOfferExchangeAdaptedType => {
    const {items} = data;

    return {
        items: items.map(({product, qty, color, sku, options}) => ({
            name: product.name,
            qty: qty || {od: 0, os: 0},
            color: color || {od: '', os: ''},
            sku,
            lensPerBox: Number(getOptionsParamFromData('lenses_per_box', options, 0)),
        })),
        isContactLenses: items.every(item => item.contactLens),
    };
};

/**
 * Adapts the data structure for fourth step.
 *
 * @param {RmaResponseDataConfirmationType} data - raw data.
 * @returns {RmaAdaptedDataConfirmationType} - adapted structure,
 */
export const fourthStepDataAdapter = (
    data: RmaResponseDataConfirmationType,
): RmaAdaptedDataConfirmationType => {
    const {items, type, country, products, comments, ...restData} = data;

    const getBoxes = (qty?: RmaQtyType) =>
        qty ? `Left eye boxes: ${qty.os}   |   Right eye boxes: ${qty.od}` : '';

    return {
        type: prop('title', RETURN_OPTIONS[type]),
        // @ts-ignore
        products: items.map(item => ({
            ...item.product,
            lensesPerBox: getOptionsParamFromData('lenses_per_box', item.options, 0),
            lensType: getOptionsParamFromData('lens_type', item.options),
            lensTypeName: getOptionsParamFromData('lens_type', item.options)?.split(' ')[0],
            isContactLenses: item.contactLens,
            boxes: getBoxes(item.qty),
        })),
        exchangeWith: products.map(({lensPerBox, ...item}) => {
            return {
                ...item,
                lensesPerBox: lensPerBox || 0,
                boxes: getBoxes(item.qty),
                lensesColor: item.color ? item.color.od : '',
            };
        }),
        isNotUsa: isNonUsaCountryCode(country),
        comments: comments.map(field => field.comment).filter(comment => Boolean(comment.trim())),
        ...restData,
    };
};

/**
 * Get user first name
 *
 * @param {string} name - full user name.
 * @returns {string} - user first name,
 */
export const getFirstName = (name = ''): string => name.split(' ')[0];

/**
 * Adapter summary data
 *
 * @param {RmaResponseDataSummaryType} data - data
 * @returns {RmaAdaptedSummaryDataType} - adapted data
 */
export const summaryDataAdapter = (data: RmaResponseDataSummaryType): RmaAdaptedSummaryDataType => {
    const {items, type, country, products, ...restData} = data;
    const getBoxes = (qty?: RmaQtyType) => (qty ? `${qty.os} Left | ${qty.od} Right` : '');

    return {
        type: prop('title', RETURN_OPTIONS[type]),
        // @ts-ignore
        products: items.map(item => ({
            ...item.product,
            lensesPerBox: getOptionsParamFromData('lenses_per_box', item.options, 0),
            boxes: getBoxes(item.qty),
        })),
        exchangeWith: products.map(({lensPerBox, ...item}) => ({
            ...item,
            lensesPerBox: lensPerBox || 0,
            boxes: getBoxes(item.qty),
            lensesColor: item.color ? item.color.od : '',
        })),
        isContactLenses: items.every(item => item.contactLens),
        isNotUsa: isNonUsaCountryCode(country),
        isCanada: country === 'CA',
        ...restData,
    };
};

/**
 * Get reason type
 *
 * @param {RmaAdaptedSummaryDataType} summaryData - data from summary step.
 * @returns {"Exchange" | "Refund" | "Store credit" | "uhc"} - type of reason,
 */
export const getReasonType = (
    summaryData: RmaAdaptedSummaryDataType,
): RETURN_TYPE.REFUND | RETURN_TYPE.EXCHANGE | 'uhc' => {
    if (summaryData.isUhc) return 'uhc';
    if (summaryData.type === RETURN_TYPE.REFUND) return RETURN_TYPE.REFUND;
    return summaryData.type;
};

/**
 * Get list type for summary page
 *
 * @param {RmaResponseDataSummaryType} data - data from rma.
 * @returns {RMA_LISTS} - type of list,
 */
export const getListTypeForSummary = ({
    isNotUsa,
    isCanada,
}: RmaAdaptedSummaryDataType): RMA_LISTS => {
    if (isNotUsa) return RMA_LISTS.OUTSIDE;
    if (isCanada) return RMA_LISTS.CANADA;

    return RMA_LISTS.REFUND;
};

/**
 * Get drop down options list
 *
 * @param {number} maxValue - max value for options.
 * @param {number} start - start number of option.
 * @returns {Array<{value: number, label: string}>} - drop down options list,
 */
export const getDropDownOptions = (
    maxValue: number,
    start = 0,
): Array<{value: number; label: string}> =>
    range(start, maxValue + 1).map((item: number) => ({
        value: item,
        label: String(item || '—'),
    }));

/**
 * Extract the MIME type from a base64 string using a regular expression.
 *
 * @param {string} encoded - base64 string
 * @returns {string | null} - mime type
 */
export const getMimeTypeFromBase64 = (encoded?: string): string | null => {
    const result = null;

    if (typeof encoded !== 'string' || !encoded.length) {
        return result;
    }

    const [, mime] = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/) || [];

    if (mime && mime.length) {
        return mime;
    }
    return result;
};

/**
 * Generate string based on lensesPerBox, lensType, lensName
 *
 * @param {number} lensesPerBox - lensesPerBox
 * @param {Maybe<string>} lensType - lensType
 * @param {string } lensName - lensName
 * @returns {string} - string from lens data
 */
export const getLensesInfo = (
    lensesPerBox = 0,
    lensType: Maybe<string> = '',
    lensName?: string,
): string => {
    const lensesPerBoxWithName =
        lensName && `${lensName} ${lensesPerBox ? `(${lensesPerBox}pk)` : ''}`;
    const lensesPerBoxWithType =
        lensType && `${lensType} ${lensesPerBox ? `| ${lensesPerBox}pk` : ''}`;

    return lensesPerBoxWithName || lensesPerBoxWithType || '';
};
